import React, { Suspense } from 'react'
import MountTransition from 'components/animation/MountTransition'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Wrapper } from './styled'
import { useSelector } from 'react-redux'
import { RootState } from 'globalStore'
import { Spin } from 'antd'
import { AnimatePresence } from 'framer-motion'
import { Footer } from 'antd/lib/layout/layout'

const AuthLayout: React.FC<any> = props => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const location = useLocation()

  if (isAuthenticated) {
    return <Navigate to='/' />
  }

  return (
    <MountTransition>
      <Wrapper className='wrapper'>
        <Suspense fallback={<Spin />}>
          <AnimatePresence exitBeforeEnter>
            <MountTransition key={location.pathname}>
              <Outlet />
            </MountTransition>
          </AnimatePresence>
        </Suspense>
        <Footer style={{ textAlign: 'center', position: 'absolute', bottom: 0, width: '100%' }}>
          Geo Location copyright 1.0.0
        </Footer>
      </Wrapper>
    </MountTransition>
  )
}

export default AuthLayout
